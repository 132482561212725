import * as React from 'react';

import AboutUsPage from '@lce/gatsby-theme-multi-site/src/pages/about-us';
import { IDefaultPageProps } from '@lce/intl-types/src/IDefaultPageProps';

const QuienesSomos: React.FC<IDefaultPageProps> = props => (
  <AboutUsPage {...props} />
);

export default QuienesSomos;
